<template>
  <WsRead
    :modelName="modelName"
    :id="$route.params.id"
    :localeMode="localeMode"
    :label="label"
    :fields="fields"
    :primary="pageSetting.primary"
    :secondary="_pageSettingSecondary"
    titleKey="name"
  >
  </WsRead>
</template>

<script>
import model from "@/__vue2stone_cms/models/contact_request_notify_mail";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    localeMode: false,
    pageSetting: {
      primary: [
        {
          type: "infoCard",
          floors: [
            {
              fields: ["name", "mail", "remark"],
            },
          ],
        },
      ],
      secondary: [],
    },
  }),
  computed: {
    _pageSettingSecondary() {
      const fields = ["is_active"];
      if (this.$config.wsmodule.country_code) {
        fields.push("country_code");
      }
      return [
        {
          type: "infoCard",
          floors: [
            {
              title: "設定",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
  },
};
</script>